.mission .title {
  color: var(--Gray-100-main, #433e3e);
  /* Medium/SemiBold */
  font-family: Montserrat;
  font-size: 39px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 46.8px */
}

.mission .title-accordion-active {
  color: var(--Blue-70---Main, #17a2b8);
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 30px */
}

.mission .title-accordion {
  color: #878484;
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 30px */
}

.mission .desc-accordion {
  color: var(--Gray-100-main, #433e3e);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}

@media only screen and (max-width: 768px) {
  .mission .title {
    font-size: 30px;
    margin-top: 40px;
  }

  .mission .title-accordion-active,
  .mission .title-accordion {
    font-size: 20px;
  }

  .mission .desc-accordion {
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .mission .title {
    font-size: 26px;
  }

  .mission .title-accordion-active,
  .mission .title-accordion {
    font-size: 18px;
  }

  .mission .desc-accordion {
    font-size: 12px;
  }
}
