ol {
    counter-reset: item;
    list-style-position: outside;
}

ol>li {
    display: block;
}

ol>li:before {
    content: counters(item, ".") ". ";
    counter-increment: item;
}