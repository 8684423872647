@import "../../var.css";

.service-section {
  scroll-margin-top: 80px;
}
.service-section h2 {
  font-size: 31px;
  font-family: Montserrat;
  font-weight: bold;
}

.service-section .title {
  color: var(--primary-70-main);
  font-family: Montserrat;
  font-size: 31px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: var(--spacing-4xl);
}

.service-section .our-service {
  color: var(--Blue-70---Main, #17a2b8);
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  margin-bottom: 20px;
}

.service-section .service-icon-active {
  width: 32px;
  height: 32px;
  color: #17a2b8;
  justify-content: center;
  margin-right: 20px;
}

.service-section .service-icon {
  width: 32px;
  height: 32px;
  color: #9d9d9d;
  justify-content: center;
  margin-right: 20px;
}

.service-section .tab-title-active {
  color: var(--Blue-70---Main, #17a2b8);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 1px;
}

.service-section .tab-title {
  color: var(--Gray-60, #9d9d9d);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  letter-spacing: 1px;
}

.service-section .tab-border-active {
  --tw-border-opacity: 1;
  border-color: #17a2b8;
}

.service-section .tab-border {
  --tw-border-opacity: 1;
  border-color: #e6e6e6;
}

.service-section .service-image {
  height: 500px;
  margin-right: 60px;
}

.service-section .service-title {
  color: var(--Gray-100-main, #433e3e);
  font-family: Montserrat;
  font-size: 39px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-align: left;
}

.service-section .service-desc {
  color: var(--Gray-90, #595454);
  text-align: justify;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

@media only screen and (max-width: 768px) {
  .service-section h2 {
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .service-section .tab-title,
  .service-section .tab-title-active {
    font-size: 12px;
  }

  .service-section .service-title {
    font-size: 26px;
  }

  .service-section .service-desc {
    font-size: 10px;
  }
}
