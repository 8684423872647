@import "../../var.css";

.hero-section {
    font-family: "Montserrat";
    padding-top: var(--spacing-4xl);
    scroll-margin-top: 80px;
}
.hero-section h5 {
    font-family: "Montserrat";
    /* font-size: 31px; */
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: var(--primary-70-main);
    padding-bottom: var(--spacing-4xl);
}
.hero-section .content {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    color: var(--gray-100-main);
}
.hero-section p {
    font-family: "Montserrat";
    /* font-size: 20px; */
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    letter-spacing: 1px;
    color: var(--gray-60);
}

.hero-section {
    font-weight: bold;
}

.span {
    color: var(--primary-70-main);
}
.hero-section button {
    display: flex;
    padding: var(--lg, 16px) 41px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    background: var(--primary-70-main);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}
.hero-section button:hover {
    background-color: var(--primary-90);
    color: #fff;
}

@media only screen and (min-width: 0) and (max-width: 639px) {
    .content {
        font-size: 24px;
    }
    .hero-desc {
        font-size: 12px;
    }
    .hero-section button {
        display: flex;
        padding: var(--md, 12px) 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        background: var(--primary-70-main);
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    }
}

.has-animation {
  position: relative;
}

.has-animation p,
.has-animation img {
  opacity: 0;
}

.has-animation.animate-in p,
.has-animation.animate-in img {
  animation: textHidden 0.1s 1.1s forwards;
}

.has-animation.animate-in:before,
.has-animation.animate-in:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 10;
}

.has-animation.animate-in:before {
  background-color: #17a2b8;
}

.has-animation.animate-in:after {
  background-color: rgb(255, 255, 255);
  animation-delay: 0.5s;
}

.has-animation.animation-ltr.animate-in:before {
  animation: revealLTR 1.8s ease;
}

.has-animation.animation-ltr.animate-in:after {
  animation: revealLTR 1s 0.6s ease;
}

.has-animation.animation-rtl.animate-in:before {
  animation: revealRTL 1.8s ease;
}

.has-animation.animation-rtl.animate-in:after {
  animation: revealRTL 1s 0.6s ease;
}




@keyframes revealRTL {
    0% {
        width: 0;
        right: 0;
    }
    65% {
        width: 100%;
        right: 0;
    }
    100% {
        width: 0;
        right: 100%;
    }
}

@keyframes revealLTR {
    0% {
        width: 0;
        left: 0;
    }
    65% {
        width: 100%;
        left: 0;
    }
    100% {
        width: 0;
        left: 100%;
    }
}

@keyframes textHidden {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
