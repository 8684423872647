@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

:root {
    font-family: "Montserrat";
    /* Colors */
    --primary-10: #ecfcff;
    --primary-20: #c2f6ff;
    --primary-30: #97f1ff;
    --primary-40: #6debff;
    --primary-50: #42e3fc;
    --primary-60: #2ac2da;
    --primary-70-main: #17a2b8;
    --primary-80: #098396;
    --primary-90: #006474;
    --primary-100: #004752;

    --secondary-10: #f2fff2;
    --secondary-20: #e2ffe2;
    --secondary-30: #c7fbc7;
    --secondary-40: #aee2ae;
    --secondary-50: #96c896;
    --secondary-60: #7faf7f;
    --secondary-70: #6a956a;
    --secondary-80-main: #557c55;
    --secondary-90: #416341;
    --secondary-100: #2f492f;

    --gray-10: #f5f5f5;
    --gray-20: #f5f5f5;
    --gray-30: #e1e1e1;
    --gray-40: #cbcbcb;
    --gray-50: #bdbcbc;
    --gray-60: #9d9d9d;
    --gray-70: #878484;
    --gray-80: #706c6c;
    --gray-90: #595454;
    --gray-100-main: #433e3e;

    /* Spacing */
    --spacing-4xs: 1px;
    --spacing-3xs: 2px;
    --spacing-2xs: 4px;
    --spacing-xs: 6px;
    --spacing-sm: 8px;
    --spacing-md: 12px;
    --spacing-lg: 16px;
    --spacing-xl: 20px;
    --spacing-2xl: 24px;
    --spacing-3xl: 32px;
    --spacing-4xl: 40px;
    --spacing-5xl: 60px;
    --spacing-6xl: 80px;
}
