@import "../../var.css";

.logo {
  max-width: 100%;
  height: auto;
}

.navbar {
  font-family: "Montserrat";
  background-color: #fff;
}
.menu-items {
  margin-left: var(--spacing-4xl);
  margin-right: var(--spacing-4xl);
}

.nav-button {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  background: var(--primary-70-main);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}
.nav-button:hover {
  background-color: var(--primary-90);
  color: #fff;
}
.nav-button-2 {
  background: var(--primary-70-main);
}
.nav-button-2:hover {
  background-color: var(--primary-90);
  color: #fff;
}
@media (max-width: 799px) {
  .menu-items {
    display: none;
  }
  .navbar.sticky {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .logo {
    max-width: 100%;
  }
  #mobile-menu-button {
    display: block;
  }

  #mobile-menu {
    display: none;
  }

  #mobile-menu.block {
    display: block;
  }
  #mobile-menu li.active a {
    color: var(--primary-70-main);
    font-weight: bold;
  }
  #mobile-menu li {
    color: var(--gray-100-main);
    font-weight: bold;
  }
  .nav-button {
    display: none;
  }
}

@media (min-width: 1023px) {
  .lg\:hidden {
    display: none;
  }

  .navbar {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:mx-8 {
    margin-left: 1rem /* 32px */;
    margin-right: 1rem /* 32px */;
  }
}

@media only screen and (min-width: 799px) and (max-width: 1023px) {
  .navbar {
    padding: 2rem;
  }
  .navbar.sticky {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .menu-items {
    display: none;
  }
  .logo {
    max-width: 100%;
  }
  .nav-button {
    display: none;
  }
  #mobile-menu-button {
    display: block;
  }

  #mobile-menu {
    display: none;
  }

  #mobile-menu.block {
    display: block;
  }
  #mobile-menu li.active a {
    color: var(--primary-70-main);
    font-weight: bold;
  }
  #mobile-menu li {
    color: var(--gray-100-main);
    font-weight: bold;
  }
}

.navbar.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease;
  background: rgba(255, 255, 255, 0.17);
  border-radius: 12px;
  backdrop-filter: blur(4.9px);
  -webkit-backdrop-filter: blur(4.9px);
  border: 1px solid rgba(255, 255, 255, 0.22);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.menu-items li.active a {
  color: var(--primary-70-main);
  font-weight: bold;
}
.menu-items li a {
  font-weight: bold;
  color: var(--gray-100-main);
}
