@import "../../var.css";

#ContactUs {
    margin-bottom: 120px;
    scroll-margin-top: 80px;
}

.title-contacts {
    color: var(--gray-90);
    text-align: center;
    margin-bottom: 80px;
}

.title-contacts h1 {
    color: var(--primary-70-main);
    font-size: 49px;
    font-weight: 600;
}

.icon {
    padding: 10px;
    color: var(--gray-10);
    background-color: var(--primary-60);
    border-radius: 50%;
}

.contact-content div p {
    padding: 0;
}
