@import "../../var.css";

.footer-section {
    background-color: var(--primary-10);
}
.footer-section-2 {
    background-color: var(--secondary-10);
}

.content-footer {
    color: var(--gray-90);
}

.gmap,
.copyright {
    color: var(--secondary-80-main);
}
